import React, {useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import SelectAppList from "../components/SelectAppList";
import Chart from "../components/OverView/Chart";
import DatePicker from "react-datepicker";
import FilterOverview from "../components/OverView/FilterOverview";
import TableOverview from "../components/OverView/TableOverview";
import {ApiPostRequest} from "../api/ApiPostRequest";
import Moment from "moment/moment";
import {ApiGetRequest} from "../api/ApiGetRequest";
import listNewNames from "../data/listNewNames";
import Tooltip from "../components/Tooltip";

function OverView(parameters) {
    const [isOpen, setIsOpen] = parameters.openSideBar;
    const [valueSelect, setValueSelect] = useState({
        icon: 'https://newapi.godoko-fsp.com/media/icons/com.smartwatch.bluetooth.sync.notifications.png',
        package: 'com.smartwatch.bluetooth.sync.notifications',
        computer: 'Комп Олега',
        comment: 'Smartwatch Bluetooth Notificator',
    });
    //data api request
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    //date range
    const currentDate = new Date();
    const defaultDateStart = new Date();
    const defaultDateEnd = new Date();

    defaultDateStart.setDate(currentDate.getDate() - 10);
    defaultDateEnd.setDate(currentDate.getDate() - 4);

    const [dateRange, setDateRange] = useState([defaultDateStart, defaultDateEnd]);
    const [startDate, endDate] = dateRange;
    const [hiddenElements, setHiddenElements] = useState(['revenue_view']);
    const [checkboxStates, setCheckboxStates] = useState({'all-geo': true});
    const [countryData, setCountryData] = useState({});
    const [checkboxTableStates, setCheckboxTableStates] = useState(
        {
            revenue_view_id_profit: true
        }
    );

    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const checkboxArray = Object.entries(checkboxStates).map(
            ([key]) => (key)
        ).filter(key => checkboxStates[key]);

        const fetchDataFromApi = async () => {
            if(startDate && endDate) {
                try {
                    const result = await ApiPostRequest('overview', {
                        "start_date": Moment(startDate).format('DD.MM.YYYY').toString(),
                        "end_date": Moment(endDate).format('DD.MM.YYYY').toString(),
                        "package": valueSelect.package,
                        "countries": checkboxArray[0] !== 'all-geo' ? checkboxArray : []
                    });
                    setData(result);
                    const resultCountry = await ApiGetRequest('getAllCountries');
                    const country =  Object.entries(resultCountry.countries).map(
                        ([key, value]) => ({
                            name: key,
                            country: value
                        }));
                    setCountryData(country);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        if(loading) {
            setIsOpen(false);
        }

        fetchDataFromApi().then(() => {});
    }, [startDate, endDate, valueSelect, checkboxStates]);

    const dataTable =  Object.entries(data).map(
        ([key, value]) => ({
            name: key,
            value: value
        })
    ).filter(item =>
        item.name !== 'Indicators' &&
        item.name !== 'Parsing' &&
        item.name !== 'Parsing_and_merged' &&
        item.name !== 'Calculated'
    );

    const getWeekBetween = (start, end) => {
        const week = [];
        let currentDate = new Date(start);

        while (currentDate <= end) {
            week.push(currentDate.toLocaleString('ua', {day: 'numeric', month: 'numeric', year: 'numeric' }));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return week;
    };

    const weekBetween = getWeekBetween(startDate, endDate);

    function dateLimit(){
        const dateLimitEnd = structuredClone(dateRange[0]);
        const maxDateRange = dateLimitEnd.setDate(dateLimitEnd.getDate() + 6);

        if(dateRange[1] === null && maxDateRange < new Date()) {
            return maxDateRange;
        }
        return new Date();
    }

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div>:
        <div className="page overview">
            <div className="block_chart_filters">
                <div className="page__block_chart">
                    <SelectAppList selectState={[valueSelect, setValueSelect]} dataLoading={[loading, setLoading]}/>
                    <Chart
                        dataChart={data.Indicators}
                        dataName={checkboxTableStates || false}
                        listNewNames={listNewNames}
                    />
                </div>
                <div>
                    <DatePicker
                        disabledKeyboardNavigation
                        maxDate={dateLimit()}
                        minDate={new Date('07.01.2023')}
                        dateFormat="dd.MM.yyyy"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleDateChange}
                        isClearable={true}
                        placeholderText="Date range"
                    />
                    <FilterOverview
                        countryData={countryData}
                        checkboxState={[checkboxStates, setCheckboxStates]}
                        loading={[loading, setLoading]}
                        selectedOption={[selectedOption, setSelectedOption]}
                    />
                </div>
            </div>
            <div className="table-container">
                <table className="header_table">
                    <thead>
                    <tr>
                        <th></th>
                        {weekBetween.map((item, k) => (
                            <th key={k}>{item}</th>
                        ))}
                    </tr>
                    </thead>
                </table>
                <div className="visible-paren">
                    {dataTable.map((items, k) => (
                        <TableOverview
                            data={items.value}
                            dataName={items.name}
                            dataVisible={false}
                            key={k}
                            dataDetePeriod={weekBetween}
                            checkboxState={[checkboxTableStates, setCheckboxTableStates]}
                            hiddenElements={[hiddenElements, setHiddenElements]}
                            listNewNames={listNewNames}
                            subItem={data.after}
                            precent={data.percent}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OverView;
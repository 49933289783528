import React, {useState, useEffect} from "react";
import Select, {components} from "react-select";
import Moment from "moment";
import DatePicker from "react-datepicker";

const Option = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const [dateRange, setDateRange] = props.selectProps.dateRange;
    const [dateRangeCurrent, setDateRangeCurrent] = useState([]);
    const [startDate, endDate] = dateRange;

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleDateChange = (date) => {
        setDateRange(date);
        setDateRangeCurrent(date);
    };

    useEffect(() => {
        let dateArr = dateRangeCurrent.filter(function (el) {
            return el != null;
        })
        if(dateArr.length === 2) {
            props.selectProps.onChange({
                value: {
                    'start': dateRange[0],
                    'end': dateRange[1]
                },
                label: 'Other'
            })
            setIsHovered(false);
        }
    }, [dateRange, dateRangeCurrent]);

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <components.Option {...props} />
            {isHovered && props.data.label === 'Other' && (
                <div className="modal-date_option">
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <DatePicker
                            maxDate={new Date()}
                            disabledKeyboardNavigation
                            dateFormat="MM.yyyy"
                            showMonthYearPicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            isClearable={true}
                            placeholderText="Date range"
                            inline
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const FiltersAppList = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [selectedOption, setSelectedOption] = parameters.selectedOption;
    const [selectedOptionDate, setSelectedOptionDate] = parameters.selectedOptionDate;
    const [multiOptions, setMultiOptions] = parameters.multiOptions;

    const endDate = Moment(new Date().setMonth(new Date().getMonth() - 1)).format('MM.YYYY').toString();

    const optionDate = [
        {
            value: new Date().setMonth(new Date().getMonth() - 1),
            label: 'Last month ' + endDate
        },
        {
            value: new Date().setMonth(new Date().getMonth() - 2),
            label: 'Last 2 month ' + Moment(new Date().setMonth(new Date().getMonth() - 2)).format('MM.YYYY').toString() + '-' + endDate
        },
        {
            value: new Date().setMonth(new Date().getMonth() - 3),
            label: 'Last 3 month ' + Moment(new Date().setMonth(new Date().getMonth() - 3)).format('MM.YYYY').toString() + '-' + endDate
        },
        {
            value: new Date().setMonth(new Date().getMonth() - 6),
            label: 'Last 6 month ' + Moment(new Date().setMonth(new Date().getMonth() - 6)).format('MM.YYYY').toString() + '-' + endDate
        },
        {
            value: new Date().setMonth(new Date().getMonth() - 12),
            label: 'Last 12 month ' + Moment(new Date().setMonth(new Date().getMonth() - 12)).format('MM.YYYY').toString() + '-' + endDate
        },
        {
            value: null,
            label: 'Other'
        }
    ];

    const countryAvailable = [];

    parameters.countryData.map(item => {
        item.country.map(value => {
            if(value.available) {
                countryAvailable.push({
                    label: `(${value.code}) ${value.country_name}`,
                    value: value.code
                });
            }
        })
    });

    countryAvailable.push({label: 'All Geo', value: ''});
    countryAvailable.sort((a, b) => a.value.localeCompare(b.value));
    countryAvailable.shift();

    const handleSelectCountryChange = (selectedOption) => {
        if(!multiOptions.includes(selectedOption.value)) {
            setSelectedOption(selectedOption);
            setMultiOptions((prevState) => (
                [
                    ...prevState,
                    selectedOption.value
                ]
            ));
            if(selectedOption.label === 'All Geo') {
                setMultiOptions([]);
            }
            setLoading(true);
        }
    };

    const handleSelectDateChange = (optionDate) => {
         if(optionDate.value !== null) {
             setSelectedOptionDate(optionDate);
             setLoading(true);
         }

         if(optionDate.label === 'Other') {
             optionDate.label = 'Other ' +
                 Moment(optionDate.value.start).format('MM.YYYY').toString() + '-' +
                 Moment(optionDate.value.end).format('MM.YYYY').toString();
         }
    };

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "270px"}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
        singleValue: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px" }),
        ValueContainer: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px" }),
        input: () => ({ margin: "4px 10px", display: "inline-grid", gridArea:" 1/1/2/3", gridTemplateColumns: "10px"}),
    };

    function multiOptionsBlock() {
        if(multiOptions) {
            return multiOptions.map((item) => {
                return (
                    <div
                        key={item}
                        className="button-checked"
                        onClick={() => multiOptionsBlockRemove(item)}>
                        {item}
                    </div>
                );
            }).slice(0,5);
        }
    }

    function multiOptionsBlockRemove(item) {
        const newMultiOptions = multiOptions.filter((element) => (element !== item));
        setMultiOptions(newMultiOptions);

        if(newMultiOptions.length === 0) {
            setSelectedOption({label: 'All Geo', value: ''});
        }
        setLoading(true);
    }

    return (
        <div>
            <div className="filters__block">
                <Select
                    value={selectedOptionDate}
                    onChange={handleSelectDateChange}
                    options={optionDate}
                    className="filters__search_input date"
                    placeholder="Search"
                    styles={styleSelect}
                    components={{ Option }}
                    dateRange={parameters.dateRange}
                />
                <div>
                    <Select
                        value={selectedOption}
                        onChange={handleSelectCountryChange}
                        options={countryAvailable}
                        isSearchable
                        className="filters__search_input geo"
                        placeholder="Search"
                        styles={styleSelect}
                    />
                    <div className="checked-block-buttons">
                        {multiOptionsBlock()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FiltersAppList;
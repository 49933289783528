import React, { useState, useRef, useEffect } from 'react';
import TipsIcon from "../img/tips_icon.svg"

const Tooltip = (parameters) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const imageRef = useRef(null);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (imageRef.current && !imageRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="image-container" ref={imageRef}>
            {showTooltip &&
                <div className="tooltip">
                    <div className="text">{parameters.dataTips}</div>
                    <i></i>
                </div>
            }
            <img src={TipsIcon} alt="" onClick={toggleTooltip}/>
        </div>
    );
};

export default Tooltip;
export const listNewNames = {
    revenue_sub: "Revenue Subscriptions",
    revenue_ad: "Revenue Ad",
    avg_cpm_ad: "CPM Ad",
    impressions_ad: "Impressions Ad",
    ads_cost: "Costs Adwords",
    ads_installs: "Installs Adwords",
    total_gp_installs: "Total user Installs",
    paid_installs: "Paid user Installs",
    gp_uninstalls: "Uninstall user",
    new_subs: "Trials",
    canceled_subs: "All subscription Cancel",
    paid_up_subscription: "Paid-up sub m",
    avg_sub_price: "Average sub m",
    profit: "Profit",
    IPI: "Impression per Install",
    StPS: "Trial to Sub",
    IPS: "Install to Sub",
    resub_12m: "Resub 12m",
    organic_user_installs: "Organic user Installs",
    CPI: "Cost per Install",
    outflow_users: "Outflow users",
    active_users_30d: "Active users 30d",
    ARPI_monthly: "ARPI monthly",
    ARPI_12m: "ARPI 12m",
    NPI_12m: "NPI 12m",
    organic_user_installs_t: "Organic user Installs t",
    outflow_t: "Outflow t",
    IPS_t: "Install to Sub t",
    StPS_t: "Trial to Sub t",
    resub_2_month_t: "Resub 2 month t",
    resub_accelerator_t: "Resub accelerator t",
    resub_12m_t: "Resub 12m t",
    active_users_30d_t: "Active users 30d t",
    ARPI_monthly_t: "ARPI monthly t",
    revenue_subscription_t: "Revenue Subscription t",
    profit_t: "Profit t",
    NPI_t: "NPI t",
    NPI_12m_t: "NPI 12m t",
    per_user_view: "Per User View",
    subscription_view: "Subscription View",
    admob_view: "Admob View",
    users_view: "Users View",
    revenue_view: "Revenue View",
    CPI_adwords: "CPI Adwords",
    ARPI_ad: "ARPI ad",
    StPS_before: "Trial to Sub before",
    IPS_before: "Install to Sub before",
    new_subs_before: "All new sub before",
    total_gp_installs_before: "Total User Installs before",
    paid_up_subscription_before: "Paid-up sub before",
    before: "Subscription view",
    trial_to_sub: "Trial to Sub",
    install_to_sub: "Install to Sub",
    paid_up_subscription_after: "Paid-up sub after",
    active_subscriptions: "All purchases",
    ad_view: "Ad view",
    ROI_12m: "ROI 12m",
    all_subscription: "Active subscriptions",
    ARPI_sub: "ARPI sub",
    Resub_2: "Resub 2",
    Resub_3: "Resub 3",
    Resub_4: "Resub 4",
    Resub_5: "Resub 5",
    Resub_6: "Resub 6",
    Resub_7: "Resub 7",
    Resub_8: "Resub 8",
    Resub_9: "Resub 9",
    Resub_10: "Resub 10",
    Resub_11: "Resub 11",
    Resub_12: "Resub 12",
    resub_week: "Resub week",
    resub_month: "Resub 12m",
    resub_year: "Resub year",
    admob: "Ad",
    paid_up_subscription_year: "Paid-up sub year",
    avg_sub_price_year: "Average sub year",
    Resub_month_2: "Resub 2m",
};

export default listNewNames;
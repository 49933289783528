import React, {useEffect, useState} from 'react';
import {ApiGetRequest} from "../api/ApiGetRequest";
import Modal from 'react-modal';
import "../styles/packageSettings.scss";
import inactive from "../img/inactive.svg";
import pen_icon from "../img/pen_icon.svg";
import closedModal from "../img/closed_modal_icon.svg";
import ImageUpload from "../components/ImageUpload"
import {ApiPostRequest} from "../api/ApiPostRequest";
import axios from 'axios';

function PackageSettings() {
    //data api request
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [checkboxStates, setCheckboxStates] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const result = await ApiGetRequest('getAppsSettings');
                setData(result.apps);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, []);

    const handleCheckboxClick = (value, available) => {
        setCheckboxStates((prevStates) => {
            return {
                ...prevStates,
                [value]: (available && prevStates[value] === undefined) ? !available : !prevStates[value],
            };
        });
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '744px',
            padding: '30px',
            borderRadius: '16px'
        },
        overlay: {
            background: "rgb(0 0 0 / 50%)",
            zIndex: '100'
        }
    };

    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [formData, setFormData] = useState({
        id: '',
        package: '',
        comment: null,
        computer: null,
    });

    function openModal(parameters) {
        setFormData({
            id: parameters.id,
            package: parameters?.package,
            comment: parameters?.comment ?? '',
            computer: parameters?.computer ?? '',
        });
        setPreviewImage(parameters.icon);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function saveChecked() {
       const dataSendChecked = Object.entries(checkboxStates).map(
           ([key, value]) => ({
               id: Number(key),
               available: value
           }
        ));
        ApiPostRequest('saveAppsSettings/', {"apps": dataSendChecked}).then(
            response => {
                window.location.reload()
            }
        );
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleClick = async (e) => {
        e.preventDefault();
        let imageResponse = null;
        if(image) {
            try {
                const fileData = new FormData();
                fileData.append('image', image);

                imageResponse = await axios.post('https://newapi.godoko-fsp.com/saveTmpImage/', fileData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Token ' + localStorage.getItem('authToken'),
                    },
                });
            } catch (error) {
                console.log(error);
            }
        }

        ApiPostRequest('saveAppsSettings/', {"apps": [
                {...formData, ...{icon: image ? imageResponse?.data.image : previewImage}}
            ]}).then(   response => {
            window.location.reload()
        }).catch(error => {
                console.log(error);
        });
    }
    return (
        loading
            ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
        <div className="page package_settings">
            <div className="page__header">
                <div className="page__header__title">Package Settings</div>
                <button className="button_save_data" onClick={saveChecked}>SAVE DATA</button>
            </div>
            <div className="package_settings__body_page">
                <div className="package_settings__filters checkbox__package">
                    <div className="package_settings__filters checkbox_block">
                        {data.map((item, index) => (
                            <div className={checkboxStates[index] ? "checkbox-wrapper active" : "checkbox-wrapper"} key={index}>
                                <input
                                    readOnly
                                    className="inp-cbx"
                                    id={item.id}
                                    type="checkbox"
                                    checked={checkboxStates[item.id] ?? item.available}
                                    onClick={() => handleCheckboxClick(item.id, item.available)}
                                />
                                <label className="cbx" htmlFor={item.id}>
                                <span>
                                    <svg width="15px" height="10px" viewBox="0 0 12 9">
                                    <polyline points="1 5 4 8 11 1"></polyline>
                                </svg>
                                </span>
                                    <div className="label_checkbox">
                                        <img className="package_img" src={item.icon ?? inactive}/>
                                        <div className="package_name">{item.package}</div>
                                        <div className="package_computer">{item.computer ? '('+item.computer+')' : ''}</div>
                                        <div className="package_comment"> {item.comment}</div>
                                    </div>
                                </label>
                                <img
                                    className="change_package"
                                    src={pen_icon}
                                    onClick={() => openModal(item)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <button className="button_save_data" onClick={saveChecked}>SAVE DATA</button>
            </div>

            <Modal ariaHideApp={false} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
              <div className="header_modal">
                  <div className="header_modal title">App settings</div>
                  <img src={closedModal} className="closed_modal" onClick={closeModal}/>
              </div>
                <form className="package_form" onSubmit={handleClick}>
                    <div className="package_form_body">
                        <ImageUpload
                            imageData={[image, setImage]}
                            previewData={[previewImage, setPreviewImage]}
                        />
                        <div className="input_text">
                            <div className="form_group">
                                <label>Package</label>
                                <input
                                    className="package_name_input"
                                    type="text"
                                    name="package"
                                    value={formData.package}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form_group">
                                <label>Computer</label>
                                <input
                                    className="computer_name_input"
                                    type="text"
                                    name="computer"
                                    value={formData.computer}
                                    onChange={handleChange}
                                    maxLength={20}
                                />
                            </div>
                            <div className="form_group">
                                <label>Comment</label>
                                <input
                                    className="comment_name_input"
                                    type="text"
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="package_form_submit">SAVE</button>
                </form>
            </Modal>
        </div>
    );
}

export default PackageSettings;
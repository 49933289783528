import React, {useEffect, useState} from 'react';
import {ApiPostRequest} from "../api/ApiPostRequest";
import FiltersGeoAdwords from "../components/GeoAdwords/FiltersGeoAdwords";
import TableAdwords from "../components/GeoAdwords/TableAdwords";
import Moment from "moment/moment";

function GeoAdwords(parameters) {
    const [isOpen, setIsOpen] = parameters.openSideBar;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isVisible, setIsVisible] = useState({
        all: true,
    });
    const currentDate = new Date();
    const defaultDateStart = new Date();
    const defaultDateEnd = new Date();

    defaultDateStart.setMonth(currentDate.getMonth() - 1);
    defaultDateEnd.setMonth(currentDate.getMonth() - 1);

    const [dateRange, setDateRange] = useState([defaultDateStart, defaultDateEnd]);

    const [sortParameter, setSortParameter] = useState('ROI-');
    const [activeItemIndex, setActiveItemIndex] = useState(6);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const result = await ApiPostRequest('GEOAdwords',
                    {
                        "start_date": Moment(dateRange[0]).format('MM.YYYY').toString(),
                        "end_date": Moment(dateRange[1]).format('MM.YYYY').toString(),
                        "sort": sortParameter
                    }
                );
                setData(result.App_list);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if(loading) {
            setIsOpen(false);
        }

        fetchDataFromApi().then(() => {});
    }, [dateRange, sortParameter]);

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page apps">
                <FiltersGeoAdwords
                    loading={[loading, setLoading]}
                    dateRange={[dateRange, setDateRange]}
                />
                <TableAdwords
                    data={data}
                    sort={[sortParameter, setSortParameter]}
                    loading={[loading, setLoading]}
                    activeItemIndex={[activeItemIndex, setActiveItemIndex]}
                    isVisible={[isVisible, setIsVisible]}
                />
            </div>
    );
}

export default GeoAdwords;
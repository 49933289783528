import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import SelectAppList from "./SelectAppList";

const TopBar = ({selectState}) => {
    const userName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        navigate('/', {replace: true});
        navigate(0);
    };
    return (
        <div className="top_bar">
            <div className="top_bar__search_form">
                <SelectAppList
                    selectState={selectState}
                    dataLoading={[loading, setLoading]}
                />
            </div>

            <div className="top_bar__panel">
                <div className="top_bar__user">{userName} {lastName}</div>
                <div className="top_bar__logout">
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
import React, {useEffect, useState} from 'react';
import {ApiGetRequest} from "../api/ApiGetRequest";
import "../styles/geoSettimgs.scss";
import {ApiPostRequest} from "../api/ApiPostRequest";

function GeoSettings() {
    const [data, setData] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDataFromApi = async () => {
                try {
                    const result = await ApiGetRequest('getAllCountries');
                    const country =  Object.entries(result.countries).map(
                        ([key, value]) => ({
                            name: key,
                            country: value
                        }));
                    setData(country);

                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
        };

        fetchDataFromApi().then(() => {});
    }, []);

    const handleCheckboxClick = (value, available) => {
        setCheckboxStates((prevStates) => {
            return {
                ...prevStates,
                [value]: (available && prevStates[value] === undefined) ? !available : !prevStates[value],
            };
        });
    };

    function saveChecked() {
        const dataSendChecked = Object.entries(checkboxStates).map(
            ([key, value]) => ({
                    code: key,
                    available: value
                }
            ));
        ApiPostRequest('saveGeoSettings/', {"geos": dataSendChecked}).then(
            response => {
                window.location.reload()
            }
        );
    }

    function countryName(name) {
        return name.replace('_', ' ');
    }

    return (
        loading
            ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
        <div className="page page_geo_settings">
            <div className="page__header">
                <div className="page__header__title">GEO Settings</div>
                <button className="button_save_data" onClick={saveChecked}>SAVE DATA</button>
            </div>
            <div className="geo_settings__body_page">
                {data.map((country, key) => (
                    <div className="geo_settings__filters checkbox__countries" key={key}>
                        <div className="geo_settings__filters title">{countryName(country.name)}</div>
                        <div className="geo_settings__filters checkbox_block">
                            {country.country.map((item, index) => (
                                <div className={checkboxStates[index] ? "checkbox-wrapper active" : "checkbox-wrapper"} key={item.code}>
                                    <input
                                        readOnly
                                        className="inp-cbx"
                                        id={item.code}
                                        type="checkbox"
                                        checked={checkboxStates[item.code] ?? item.available}
                                        onClick={() => handleCheckboxClick(item.code, item.available)}
                                    />
                                    <label className="cbx" htmlFor={item.code}>
                                    <span>
                                        <svg width="15px" height="10px" viewBox="0 0 12 9">
                                        <polyline points="1 5 4 8 11 1"></polyline>
                                    </svg>
                                    </span>
                                        <div className="label_checkbox">
                                            <div className="country_code">{item.code}</div>
                                            <div className="country_space"></div>
                                            <div className="country_name">{item.country_name}</div>
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <button className="button_save_data" onClick={saveChecked}>SAVE DATA</button>
            </div>
        </div>
    );
}

export default GeoSettings;
import React, {useEffect, useState} from 'react';
import {ApiPostRequest} from "../api/ApiPostRequest";
import "../styles/appList.scss";
import FiltersAppList from "../components/AppList/FiltersAppList";
import {ApiGetRequest} from "../api/ApiGetRequest";
import TableAppList from "../components/AppList/TableAppList";
import Moment from "moment/moment";

function AppList(parameters) {
    const [isOpen, setIsOpen] = parameters.openSideBar;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [countryData, setCountryData] = useState({});
    const [selectedOption, setSelectedOption] = useState({label: 'All Geo', value: ''});
    const [selectedOptionDate, setSelectedOptionDate] = useState({
            value: new Date().setMonth(new Date().getMonth() - 1),
            label: 'Last month ' + Moment(new Date().setMonth(new Date().getMonth() - 1)).format('MM.YYYY').toString()
        }
    );
    const [multiOptions, setMultiOptions] = useState([]);
    const [sortParameter, setSortParameter] = useState('-Profit');
    const [activeItemIndex, setActiveItemIndex] = useState(5);
    const [dateRange, setDateRange] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
              let startDate = Moment(selectedOptionDate.value).format('MM.YYYY').toString();
              let endDate = Moment(new Date().setMonth(new Date().getMonth() - 1)).format('MM.YYYY').toString();

                  if(selectedOptionDate.value?.start !== undefined && selectedOptionDate.value?.end !== undefined) {
                      startDate = Moment(selectedOptionDate.value.start).format('MM.YYYY').toString();
                      endDate =  Moment(selectedOptionDate.value.end).format('MM.YYYY').toString();
                  }

                 const result = await ApiPostRequest('appListPage',
                    {
                        "start_date": startDate,
                        "end_date": endDate,
                        "countries": multiOptions ? multiOptions : [],
                        "sort": sortParameter
                    }
                );

                setData(result);

                const resultCountry = await ApiGetRequest('getAllCountries');
                const country =  Object.entries(resultCountry.countries).map(
                    ([key, value]) => ({
                        name: key,
                        country: value
                    }));
                setCountryData(country);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if(loading) {
            setIsOpen(false);
        }

        fetchDataFromApi().then(() => {});
    }, [selectedOptionDate, multiOptions, sortParameter]);

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
        <div className="page apps">
            <div className="filters">
                <FiltersAppList
                    countryData={countryData}
                    loading={[loading, setLoading]}
                    selectedOption={[selectedOption, setSelectedOption]}
                    selectedOptionDate={[selectedOptionDate, setSelectedOptionDate]}
                    multiOptions={[multiOptions, setMultiOptions]}
                    dateRange={[dateRange, setDateRange]}
                />
            </div>
            <TableAppList
                data={data}
                sort={[sortParameter, setSortParameter]}
                loading={[loading, setLoading]}
                activeItemIndex={[activeItemIndex, setActiveItemIndex]}
            />
        </div>
    );
}

export default AppList;

import React from "react";

const TableRtbGeo = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [sortParameter, setSortParameter] = parameters.sort;
    const [activeItemIndex, setActiveItemIndex] = parameters.activeItemIndex;

    function sort(name, itemNum) {
        if(name+'-' === sortParameter) {
            setSortParameter(name);
        } else  {
            setSortParameter(name+'-');
        }
        setActiveItemIndex(itemNum);
        setLoading(true);
    }

        return (
            <div>
                <div className="title_table">GEO list</div>
                <div className="table_wrapper">
                    <table>
                        <tbody>
                        <tr>
                            <th className='geo_th'>GEO</th>
                            <th className={activeItemIndex === 1 ? sortParameter === 'ads_installs' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('ads_installs', 1)}>Installs Adwords</th>
                            <th className={activeItemIndex === 2 ? sortParameter === 'total_gp_installs' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('total_gp_installs', 2)}>Total user Installs</th>
                            <th className={activeItemIndex === 3 ? sortParameter === 'total_income' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('total_income', 3)}>Total income</th>
                            <th className={activeItemIndex === 4 ? sortParameter === 'profit' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('profit', 4)}>Profit</th>
                            <th className={activeItemIndex === 5 ? sortParameter === 'CPI_adwords' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('CPI_adwords', 5)}>CPI Adwords</th>
                            <th className={activeItemIndex === 6 ? sortParameter === 'CPI' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('CPI', 6)}>Cost per Install</th>
                            <th className={activeItemIndex === 7 ? sortParameter === 'cpm_rtb' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('cpm_rtb', 7)}>CPM RTB</th>
                            {/*<th className={activeItemIndex === 8 ? sortParameter === 'active_users_rtb' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('active_users_rtb', 8)}>Active users RTB</th>*/}
                            <th className={activeItemIndex === 9 ? sortParameter === 'new_users_rtb' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('new_users_rtb', 9)}>New users RTB</th>
                            <th className={activeItemIndex === 10 ? sortParameter === 'ecpm_faucet' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('ecpm_faucet', 10)}>CPM Faucet</th>
                            <th className={activeItemIndex === 11 ? sortParameter === 'avg_ARPI_12m' ? "sortItem active asc" : "sortItem active" : "sortItem"} onClick={e => sort('avg_ARPI_12m', 11)}>ARPI 12m</th>
                        </tr>
                        {parameters.data.map((item, key) => {
                           return (
                               <tr key={key}>
                                   <td className="name_row">
                                       <div className="code_country">
                                           {item.code}
                                       </div>
                                       <div className="name_country">{item.country_name}</div>
                                   </td>
                                   <td>
                                       {item.ads_installs ?? '-'}
                                   </td>
                                   <td>
                                       {item.total_gp_installs ?? '-'}
                                   </td>
                                   <td>
                                       {item.total_income ? item.total_income + ' $' : '-'}
                                   </td>
                                   <td>
                                       {item.profit ? item.profit + ' $' : '-'}
                                   </td>
                                   <td>
                                       {item.CPI_adwords ? item.CPI_adwords + ' $' : '-'}
                                   </td>
                                   <td>
                                       {item.CPI ? item.CPI + ' $' : '-'}
                                   </td>
                                   <td>
                                       {item.cpm_rtb ? item.cpm_rtb + ' $' : '-'}
                                   </td>
                                   {/*<td>*/}
                                   {/*    {item.active_users_rtb ?? '-'}*/}
                                   {/*</td>*/}
                                   <td>
                                       {item.new_users_rtb ?? '-'}
                                   </td>
                                   <td>
                                       {item.ecpm_faucet ? item.ecpm_faucet + ' $' : '-'}
                                   </td>
                                   <td>
                                       {item.avg_ARPI_12m ?? '-'}
                                   </td>
                               </tr>
                           );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
}

export default TableRtbGeo;
import axios from 'axios';

const API_URL = 'https://newapi.godoko-fsp.com';

export const ApiGetRequest =  async (url)  => {
    try {
        const response = await axios.get(
            `${API_URL}/${url}`,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('authToken'),
                }
            }
        );
        return response.data;

    } catch (error) {
        console.error('Error loading data:', error);
        throw error;
    }
};
import React, {useState, useEffect} from 'react';
import Moment from "moment";
import DatePicker from "react-datepicker";
import '../styles/overViewMonth.scss';
import {ApiPostRequest} from "../api/ApiPostRequest";
import TableOverview from "../components/OverViewMonth/TableOverview";
import Chart from "../components/OverViewMonth/Chart";
import {ApiGetRequest} from "../api/ApiGetRequest";
import FilterOverview from "../components/OverViewMonth/FilterOverview";
import SelectAppList from "../components/SelectAppList";
import listNewNames from "../data/listNewNames.js";

function OverViewMonth(parameters) {
    const [isOpen, setIsOpen] = parameters.openSideBar;
    const [valueSelect, setValueSelect] = useState(
        Object.keys(parameters.dataSelect).length !== 0
            ? parameters.dataSelect
            : {
                icon: 'https://newapi.godoko-fsp.com/media/icons/com.smartwatch.bluetooth.sync.notifications.png',
                package: 'com.smartwatch.bluetooth.sync.notifications',
                computer: 'Комп Олега',
                comment: 'Smartwatch Bluetooth Notificator',
            }
    );
    const [selectedOption, setSelectedOption] = useState(null);

    //data api request
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    //date range

    const currentDate = new Date();
    const defaultDateStart = new Date(currentDate);
    const defaultDateEnd = new Date(currentDate);

    defaultDateStart.setMonth(currentDate.getMonth() - 6);
    defaultDateEnd.setMonth(currentDate.getMonth() - 1);

    const [dateRange, setDateRange] = useState([defaultDateStart, defaultDateEnd]);
    const [startDate, endDate] = dateRange;
    const [hiddenElements, setHiddenElements] = useState(['revenue_view']);
    const [checkboxStates, setCheckboxStates] = useState({'all-geo': true});
    const [countryData, setCountryData] = useState({});
    const [checkboxTableStates, setCheckboxTableStates] = useState(
        {
            revenue_view_id_profit: true
        }
    );

     useEffect(() => {
        const checkboxArray = Object.entries(checkboxStates).map(
            ([key]) => (key)
        ).filter(key => checkboxStates[key]);

        const fetchDataFromApi = async () => {
            if(startDate && endDate) {
                try {
                    const result = await ApiPostRequest('overviewMonthly', {
                        "start_date": Moment(startDate).format('MM.YYYY').toString(),
                        "end_date": Moment(endDate).format('MM.YYYY').toString(),
                        "package": valueSelect.package,
                        "countries": checkboxArray[0] !== 'all-geo' ? checkboxArray : []
                    });
                    setData(result);
                    const resultCountry = await ApiGetRequest('getAllCountries');
                    const country =  Object.entries(resultCountry.countries).map(
                        ([key, value]) => ({
                            name: key,
                            country: value
                        }));
                    setCountryData(country);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

         if(loading) {
             setIsOpen(false);
         }

        fetchDataFromApi().then(() => {});
    }, [startDate, endDate, valueSelect, checkboxStates]);

    const dataTable =  Object.entries(data).map(
        ([key, value]) => ({
            name: key,
            value: value
        })
    ).filter(item =>
        item.name !== 'Indicators' &&
        item.name !== 'Parsing' &&
        item.name !== 'Parsing_and_merged' &&
        item.name !== 'Calculated' &&
        item.name !== 'Top_3' &&
        item.name !== 'Avarage'
    );

    const getMonthsBetween = (start, end) => {
        const months = [];
        let currentDate = new Date(start);

        while (currentDate <= end) {
            months.push(currentDate.toLocaleString('ua', { month: 'numeric', year: 'numeric' }));
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        return months;
    };

    const monthsBetween = getMonthsBetween(startDate, endDate);

    function dateLimit(){
         const dateLimitEnd = structuredClone(dateRange[0]);
         const maxDateRange = dateLimitEnd.setMonth(dateLimitEnd.getMonth() + 11);

        if(dateRange[1] === null && maxDateRange < new Date()) {
            return maxDateRange;
        }
        return new Date();
    }

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
             setLoading(true);
        }
    };

    return (
        loading ? <div className="loader_page">
            <div className="lds-dual-ring"></div>
            </div>:
        <div className="page overview_month">
            <div className="block_chart_filters">
                <div className="page__block_chart">
                    <SelectAppList
                        selectState={[valueSelect, setValueSelect]}
                        dataLoading={[loading, setLoading]}
                    />
                    <Chart
                        dataChart={data.Indicators}
                        dataSubAvarage={data.Avarage}
                        dataName={checkboxTableStates || false}
                        listNewNames={listNewNames}
                    />
                </div>
                <div>
                    <DatePicker
                      disabledKeyboardNavigation
                      maxDate={dateLimit()}
                      dateFormat="MM.yyyy"
                      showMonthYearPicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={handleDateChange}
                      isClearable={true}
                      placeholderText="Date range"
                    />
                    <FilterOverview
                      countryData={countryData}
                      checkboxState={[checkboxStates, setCheckboxStates]}
                      loading={[loading, setLoading]}
                      selectedOption={[selectedOption, setSelectedOption]}
                    />
                </div>
            </div>
            <div className="table-container">
                <table className="header_table">
                    <thead>
                    <tr>
                        <th></th>
                        {monthsBetween.map((item, k) => (
                            <th key={k}>{item}</th>
                        ))}
                    </tr>
                    </thead>
                </table>
                <div className="visible-paren">
                    {dataTable.map((items, k) => (
                        <TableOverview
                            data={items.value}
                            dataName={items.name}
                            dataVisible={false}
                            key={k}
                            dataDetePeriod={monthsBetween}
                            checkboxState={[checkboxTableStates, setCheckboxTableStates]}
                            hiddenElements={[hiddenElements, setHiddenElements]}
                            listNewNames={listNewNames}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OverViewMonth;
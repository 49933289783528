import React from 'react';
import inactive from "../img/inactive.svg";

const ImageUpload = (parameters) => {
    const [image, setImage] = parameters.imageData;
    const [previewImage, setPreviewImage] = parameters.previewData;

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        // Load the old image if available
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
    };

    return (
        <div className="image_input">
            <img className="preview" src={previewImage ?? inactive} alt="" />
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
            />
        </div>
    );
};

export default ImageUpload;
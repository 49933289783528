import React from "react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";

const CustomTooltip = ({ active, payload, label, dataName}) => {
    const addDollars = [
        'Revenue Subscriptions', 'Revenue Admob',
        'CPM Admob', 'Costs Adwords',
        'Average subscription', 'Profit',
        'IPI', 'StPS', 'IPS', 'Cost per install',
        'ARPI monthly', 'ARPI 12m', 'NPI', 'NPI 12m', 'Cost per Install',
        'ARPI ad', 'Cost per install Adwords', 'Revenue Ad', 'CPM Ad', 'CPM Admob t', 'ARPI sub'
    ];

    if (active) {
        return (
            <div
                className="custom-tooltip"
                style={{background: "#fff", padding: "10px", border: "1px solid #cccccc"}}
            >
                {payload.map((item, index) => {
                    if(item.value !== undefined) {
                        if(dataName[item.dataKey] !== undefined) {
                            if(addDollars.indexOf(dataName[item.dataKey]) > -1) {
                                return <p style={{"color": item.stroke, padding: "4px 0"}} key={index}>
                                    {`${dataName[item.dataKey]}: ${item.value === null ? '-' : item.value} $`}
                                </p>;
                            }
                            if(item.dataKey.match('StPS') || item.dataKey.match('IPS') || item.dataKey.match('outflow_users') || item.dataKey.match('to_sub') || item.dataKey.match('ROI_12m')) {
                                return <p style={{"color": item.stroke, padding: "4px 0"}} key={index}>
                                    {`${dataName[item.dataKey]}: ${item.value === null ? '-' : item.value} %`}
                                </p>;
                            }

                            return <p style={{"color": item.stroke, padding: "4px 0"}} key={index}>
                                {`${dataName[item.dataKey]}: ${item.value === null ? '-' : item.value}`}
                            </p>;
                        }

                        return <p style={{"color": item.stroke, padding: "4px 0"}} key={index}>
                            {`${item.dataKey}: ${item.value === null ? '-' : item.value}`}
                        </p>;
                    }
                })}

                <p style={{color: "#000", padding: "4px 0"}}>{label}</p>
            </div>
        );
    }

    return null;
};

const Chart = (parameters) => {
    const listLine = Object.entries(parameters.dataName);

    function dotActive(nameLine) {
        const arrActive = [];
        listLine.map(index => {
            Object.entries(parameters.dataChart).map(item => (
                item.map(value => {
                     if(value[nameLine] !== undefined && value[nameLine] !== null) {
                        arrActive.push(value[nameLine])
                    }
                })
            ))
        });

        const uniqueActive = arrActive.filter(function(item, pos) {
            return arrActive.indexOf(item) === pos;
        })

        return uniqueActive.length === 1 ? {fill: "#8884d8", r: 6} : false;
    }

    return (
        <ResponsiveContainer height='80%' >
            <LineChart data={parameters.dataChart} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                <CartesianGrid />
                <XAxis dataKey="date" interval="preserveStartEnd" tick={{ dy: 10, dx: 5 }} fill={"#12A4ED"}/>
                <YAxis yAxisId="right" orientation="right" fill={"#12A4ED"} width={95}/>
                <Tooltip filterNull={false} content={<CustomTooltip dataName={parameters.listNewNames}/>}/>
                <Legend />
                {listLine.map(([key, value], index) =>
                    (
                    <Line
                        connectNulls={true}
                        key={index}
                        yAxisId="right"
                        type="monotone"
                        dataKey={key.split('_id_')[1]}
                        stroke={key.slice(-2) === '_t' || (key === 'after_id_paid_up_subscription_after') ? "#C4C4C4" : "#8884d8"}
                        activeDot={{r: 8}}
                        strokeWidth={2}
                        dot={dotActive(key.split('_id_')[1])}
                    />
                )
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default Chart;
import React, {useRef, useEffect, useState} from "react";
import Select from "react-select";

const FilterOverview = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [scrollAfterLoad, setScrollAfterLoad] = useState(false);
    const countryListRef = useRef(null);
    const [checkboxStates, setCheckboxStates] = parameters.checkboxState;
    const [selectedOption, setSelectedOption] = parameters.selectedOption;

    const countryAvailable = [];

    parameters.countryData.map(item => {
        item.country.map(value => {
            if(value.available) {
                countryAvailable.push({
                    label: `(${value.code}) ${value.country_name}`,
                    value: value.code
                });
            }
        })
    });

    countryAvailable.sort((a, b) => a.value.localeCompare(b.value));
    countryAvailable.shift();

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "200px"}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
    };

    function disableCheckAllGeo() {
        const firstValue = Object.keys(checkboxStates)[0];
        if(firstValue === 'all-geo') {
            setCheckboxStates({});
        }
    }

    const scrollToCountry = (country) => {
        const countryElement = countryListRef.current?.querySelector(`#${country}`);
        if (countryElement) {
            countryElement.scrollIntoView({behavior: 'smooth',block: 'center', inline: 'nearest'  });
        }
    };

    const handleSelectChange = (selectedOption) => {
        disableCheckAllGeo();
        scrollToCountry(selectedOption.value);
        setSelectedOption(selectedOption);
        setCheckboxStates((prevState) => (
            {
                ...prevState,
                [selectedOption.value]: true,
            }
        ));
        setLoading(true);
    };

    const handleCheckboxClick = (value) => {
        if(value === 'all-geo' && checkboxStates['all-geo'] === undefined) {
            setSelectedOption(null);
            setCheckboxStates({});
            setCheckboxStates({'all-geo': true});
            setLoading(true);
        } else {
            disableCheckAllGeo();
            setCheckboxStates((prevStates) => {
                const newStates = {
                    ...prevStates,
                    [value]: !prevStates[value],
                };

                setSelectedOption(countryAvailable.find((element) => element.value === value));
                setLoading(true);
                return newStates;
            });
        }
    };

    function countryName(name) {
        return name.replace('_', ' ');
    }

    useEffect(() => {
        if(!scrollAfterLoad && selectedOption?.value) {
            scrollToCountry(selectedOption?.value);
            setScrollAfterLoad(true);
        }
    }, [scrollAfterLoad, selectedOption]);


    function multiOptions() {
       return countryAvailable.map((item) => {
            if(checkboxStates[item.value] && checkboxStates[item.value] !== undefined) {
                return (
                    <div key={item.value} className="button-checked" onClick={() => handleCheckboxClick(item.value)}>{item.value}</div>
                );
            }
        })
    }

    const countMultiOptions = Object.values(checkboxStates).map((item) => {
        return item;
    }).filter(function( item ) {
        return item !== false;
    });

    return (
        <div className="filters" ref={countryListRef}>
            <div className="filters__header">
                <div className="filters__title">Location</div>
                <div className={checkboxStates['all-geo'] ? "checkbox-wrapper all_geo active" : "checkbox-wrapper all_geo"}>
                    <input
                        readOnly
                        className="inp-cbx"
                        id="all-geo"
                        type="checkbox"
                        checked={checkboxStates['all-geo'] || countMultiOptions.length === 0}
                        onClick={() => handleCheckboxClick('all-geo')}
                    />
                    <label className="cbx" htmlFor="all-geo">
                        <span>
                            <svg width="12px" height="9px" viewBox="0 0 12 9">
                                <polyline points="1 5 4 8 11 1"></polyline>
                            </svg>
                        </span>
                        <span>All GEO</span>
                    </label>
                </div>
                <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    options={countryAvailable}
                    isSearchable
                    className="filters__search_input"
                    placeholder="Search"
                    styles={styleSelect}
                />
                <div className="checked-block-buttons">
                    {multiOptions()}
                </div>
            </div>
            {parameters.countryData ? parameters.countryData.map((country, key) => (
                <div className="filters__checkbox__countries" key={key}>
                    <div className="filters__checkbox__bloks title">{countryName(country.name)}</div>
                    <div className="filters__checkbox__bloks">
                        {/* eslint-disable-next-line array-callback-return */}
                        {country.country.map((item) => {
                            if(item.available) {
                              return (<div
                                  className={checkboxStates[item.code] ? "checkbox-wrapper active" : "checkbox-wrapper"}
                                  key={item.code}>
                                  <input
                                      readOnly
                                      className="inp-cbx"
                                      id={item.code}
                                      type="checkbox"
                                      checked={checkboxStates[item.code] || false}
                                      onClick={() => handleCheckboxClick(item.code)}
                                  />
                                  <label className="cbx" htmlFor={item.code}>
                                    <span>
                                        <svg width="12px" height="9px" viewBox="0 0 12 9">
                                        <polyline points="1 5 4 8 11 1"></polyline>
                                    </svg>
                                    </span>
                                      <span>{item.code}</span>
                                  </label>
                              </div>)
                            }
                            }
                        )}
                    </div>
                </div>
            )) : null}
        </div>
    );
}

export default FilterOverview;
import React, {useState} from "react";
import DatePicker from "react-datepicker";

const FiltersGeoAdwords = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [dateRange, setDateRange] = parameters.dateRange;
    const [startDate, endDate] = dateRange;

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    return (
        <div className="filters_wrapper">
            <div className="field_group">
                <DatePicker
                    disabledKeyboardNavigation
                    maxDate={new Date()}
                    showMonthYearPicker
                    dateFormat="MM.yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateChange}
                    isClearable={true}
                    placeholderText="Date range"
                />
            </div>
        </div>
    )
}

export default FiltersGeoAdwords;
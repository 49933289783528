import React from "react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";

const CustomTooltip = ({ active, payload, label, dataName, dataSubAvarage, dataSubTop}) => {
    const addDollars = [
        'revenue_sub', 'avg_cpm_ad', 'ads_cost',
        'avg_sub_price', 'profit', 'IPI', 'CPI',
        'ARPI_monthly', 'ARPI_12m', 'NPI', 'NPI_12m', 'CPI',
        'ARPI_ad', 'CPI_adwords', 'revenue_ad', 'avg_cpm_ad', 'ARPI_sub',
        'avg_cpm_ad_average', 'ARPI_ad_average', 'ARPI_sub_average', 'ARPI_sub_average',
        'avg_cpm_ad_top', 'ARPI_ad_top', 'ARPI_sub_top', 'ARPI_12m_top', 'ARPI_12m_average'
    ];

    const addPercent = [
        'ROI_12m', 'IPS_average', 'StPS_average', 'resub_2m_average', 'ROI_12m_average', 'IPS_top', 'StPS_top', 'IPS',
        'resub_2m_top', 'ROI_12m_top', 'outflow_users_top', 'outflow_users_average', 'outflow_users', 'install_to_sub',
        'StPS', 'Resub_2'
    ];

    function renderSubToolTip(item) {
        let objAverage = null;
        if (dataSubAvarage[item.dataKey + '_average'] !== undefined) {
            let dataSub = dataSubAvarage[item.dataKey + '_average'].find(o => o.date === item.payload.date);
            if (dataSub !== undefined) {
                objAverage = {
                    value: dataSub.value
                };
            }
        }

        const averageDollar = addDollars.indexOf(item.dataKey + '_average') > -1 ? ' $' : '';
        const averagePercent = addPercent.indexOf(item.dataKey + '_average') > -1 ? ' %' : '';

        return (
            <div>
                <p style={{"color": "#54BC5E", "padding": "4px 0"}}>
                    {(objAverage?.value !== undefined)
                        ? `Average:  ${objAverage?.value}` + averageDollar + averagePercent
                        : ''
                    }<br/>
                </p>
            </div>
        );
    }

    function symbol(name, value) {
        if (addDollars.indexOf(name) > -1) {
            return value + ' $';
        }

        if (addPercent.indexOf(name) > -1) {
            return value + ' %';
        }

        return value;
    }

    if (active) {
        return (
            <div className="custom-tooltip"
                style={{background: "#fff", padding: "10px", border: "1px solid #cccccc"}}>
                {payload.map((item, index) => {
                    if (item.value !== undefined) {
                        if (dataName[item.dataKey] !== undefined) {
                            return <div key={index}>
                                <p style={{"color": item.stroke, padding: "4px 0"}} key={index}>
                                    {`${dataName[item.dataKey]}: ${
                                        item.value === null ? '-' : symbol(item.dataKey,  item.value)}`
                                    }<br/>
                                </p>
                                {renderSubToolTip(item)}
                            </div>;
                        }
                    }
                })}
                <p style={{color: "#000", padding: "4px 0"}}>{label}</p>
            </div>
        );
    }

    return null;
};

const Chart = (parameters) => {
    const listLine = Object.entries(parameters.dataName);

    function dotActive(nameLine) {
        const arrActive = [];
        listLine.map(index => {
            Object.entries(parameters.dataChart).map(item => (
                item.map(value => {
                     if(value[nameLine] !== undefined && value[nameLine] !== null) {
                        arrActive.push(value[nameLine])
                    }
                })
            ))
        });

        const uniqueActive = arrActive.filter(function(item, pos) {
            return arrActive.indexOf(item) === pos;
        })

        return uniqueActive.length === 1 ? {fill: "#8884d8", r: 6} : false;
    }

    listLine.push([listLine[0][0]+'_average', true]);
    listLine.push([listLine[0][0]+'_top_0', true]);
    listLine.push([listLine[0][0]+'_top_1', true]);
    listLine.push([listLine[0][0]+'_top_2', true]);

    return (
        <ResponsiveContainer height='80%' >
            <LineChart data={parameters.dataChart} margin={{ top: 20, right: 0, left: 0, bottom: 0 }}>
                <CartesianGrid />
                <XAxis dataKey="date" interval="preserveStartEnd" tick={{ dy: 10, dx: 5 }} fill={"#12A4ED"}/>
                <YAxis yAxisId="right" orientation="right" fill={"#12A4ED"} width={95}/>
                <Tooltip filterNull={false} content={
                    <CustomTooltip
                        dataName={parameters?.listNewNames}
                        dataSubAvarage={parameters?.dataSubAvarage}
                        dataSubTop={parameters?.dataSubTop}
                    />
                }/>
                <Legend />
                {listLine.map(([key, value], index) =>
                    (
                    <Line
                        connectNulls={true}
                        key={index}
                        yAxisId="right"
                        type="monotone"
                        dataKey={key.split('_id_')[1]}
                        stroke={ key.slice(-8) === '_average' ? "#54BC5E" : key.slice(-5) === 'top_0'
                        || key.slice(-5) === 'top_1' || key.slice(-5) === 'top_2'
                            ? "#9e9fa8"
                            : "#8884d8"
                    }
                        activeDot={{r: 8}}
                        strokeWidth={2}
                        dot={dotActive(key.split('_id_')[1])}
                    />
                )
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default Chart;
import React from 'react';
import {useLocation} from "react-router-dom";
import Tooltip from "../Tooltip";

const TableOverview = (parameters) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const [hiddenElements, setHiddenElements] = parameters.hiddenElements;
    const [checkboxTableStates, setCheckboxTableStates] = parameters.checkboxState;

    const addDollars = [
        'Revenue Subscriptions', 'Revenue Admob',
        'CPM Admob', 'Costs Adwords',
        'Average subscription', 'Profit',
        'IPI', 'StPS', 'IPS', 'Cost per install',
        'ARPI monthly', 'ARPI 12m', 'NPI', 'NPI 12m', 'Cost per Install',
        'ARPI ad', 'Cost per install Adwords', 'Revenue Ad', 'CPM Ad', 'CPM Admob t', 'ARPI sub'
    ];

    const tips = {
        new_subs: 'All new subscriptions per day (paid and unpaid)',
        trial_to_sub: 'Percentage of paid trials (only monthly) per day',
        install_to_sub: 'Percentage of paid trials (only monthly) per day',
        CPI: 'Cost of one total install'
    }

    const handleClick = (event) => {
        const clickedElementId = event.target.id;
        if (hiddenElements.includes(clickedElementId)) {
            setHiddenElements(hiddenElements.filter(id => id !== clickedElementId));
        } else {
            setHiddenElements([...hiddenElements, clickedElementId]);
        }
    };

    const data = Object.entries(parameters.data).map(([key, value]) => ({
        name: key,
        value: Object.entries(value).map(([key_sub, value_sub])=> (value_sub))
    }));

    const handleCheckboxClick = (value) => {
        const newValueCheckboxList =  value.split('_id_');

        setCheckboxTableStates({});
        saveCheckboxTable(value, value);

        if(newValueCheckboxList[1] !== 'profit' && newValueCheckboxList[1] !== 'outflow_users'
        && newValueCheckboxList[1 ]!== 'active_users_30d' && newValueCheckboxList[1] !== 'revenue_ad') {
            switch (newValueCheckboxList[1]) {
                case 'avg_cpm_ad':
                    saveCheckboxTable('subItem_id_CPM_admob_t', value);
                    break;
                case  'outflow_users':
                    saveCheckboxTable('subItem_id_outflow_t', value);
                    break;
            }
            if(splitLocation[1] === '' || splitLocation[1] === 'overview_month') {
                setCheckboxTableStates((prevState) => ({
                    ...prevState,
                    ['subItem_id_' + newValueCheckboxList[1] + '_t']: !prevState[value],
                }));
            } else {
                if('new_subs' === newValueCheckboxList[1]) {
                    setCheckboxTableStates((prevState) => ({
                        ...prevState,
                        ['after_id_paid_up_subscription_after']: !prevState['after_id_paid_up_subscription_after'],
                    }));
                }
            }
        }

        Object.keys(checkboxTableStates).forEach((item, index) => {
            const nameKeyArray =  item.split('_id_');
            if(nameKeyArray[0] === newValueCheckboxList[0]) {
                delete checkboxTableStates[item]
            }
        });
    };

    function saveCheckboxTable(key, value) {
        setCheckboxTableStates((prevState) => ({
            ...prevState,
            [key]: !prevState[value],
        }));
    }

    function renameItems(name) {
        return parameters.listNewNames[name] ?? name;
    }

    function renderValueTable(name, value) {
        if(name.includes('Trial to Sub') || name.includes('Install to Sub') || name.includes('Outflow users') || name.includes('ROI 12m')) {
            return  value + ' %';
        }

        return (addDollars.indexOf(name) > -1 )? value + ' $' : value;
    }

    function subValueRow(parameters, key,  name) {
        if(name !== 'profit' && name !== 'outflow_users' && name !== 'active_users_30d' && name !== 'revenue_admob') {
           if(splitLocation[1] === '' || splitLocation[1] === 'overview_month') {
               return parameters.subItem[name+'_t'] !== undefined && name !== 'outflow_t'
                   ? parameters.subItem[name + '_t'][key].value ?? '-'
                   : '';
           } else {
              return (parameters.subItem['paid_up_subscription_after'] !== undefined && name === 'new_subs')
                ? parameters.subItem['paid_up_subscription_after'][key].value ?? '-'
                : '';
           }
        }
    }

    function percentValue(parameters, key,  name) {
        if(name === 'new_subs' && parameters.precent) {
           return (
               <span className="percent">
                   {parameters.precent.new_subs_percent[key].value || parameters.precent.new_subs_percent[key].value === 0
                       ? percentRender(parameters.precent.new_subs_percent[key].value)
                       : '-'
                   }<br/>
                   {parameters.precent.paid_up_subscription_after_percent[key].value || parameters.precent.paid_up_subscription_after_percent[key].value === 0
                       ? percentRender(parameters.precent.paid_up_subscription_after_percent[key].value)
                       : '-'
                   }
               </span>
           );
        }
    }

    function percentRender(percent) {
        if(String(percent).search('-') === 0) {
            return (<span className="minus">{String(percent).replace('-', '')} %</span> )
        }
        return (<span className="plus">{percent} %</span> )
    }

    function itemsName(name) {
        let subItem = null;
        if('new_subs' === name) {
            subItem = 'Subscriptions';
        }

        if(tips[name]) {
            return (
                <div>
                    <div className="tips_name">
                        <div className="name">{renameItems(name)}</div>
                        <Tooltip dataTips={tips[name]}/>
                    </div>
                    <div className="subItem">{subItem}</div>
                </div>
            );
        }
        return renameItems(name);
    }

    return (
        <div className="table-block" id={parameters.dataName}>
            {parameters.dataName && parameters.dataName !== 'default' ?
                <table className="table-block_title">
                    <tbody>
                    <tr>
                        <td
                            id={parameters.dataName}
                            className={
                                !hiddenElements.includes(parameters.dataName) ? 'parent_table sub hidden' : 'parent_table sub visible'
                            }
                            onClick={handleClick}>{parameters.listNewNames[parameters.dataName] ?? parameters.dataName}
                        </td>
                        {parameters.dataDetePeriod.map((items, k) => (
                            <td key={k}></td>
                        ))}
                    </tr>
                    </tbody>
                </table> : null}
                    <table className={!hiddenElements.includes(parameters.dataName) ? 'hidden' : 'visible'}>
            <tbody>
            {data.map((item, key_item) => (
                <tr key={key_item}>
                    <td>
                        <div className="name_row">
                            <div>{itemsName(item.name)}</div>
                            <div className={'checkbox_table '+parameters.dataName}>
                                <input
                                    readOnly
                                    type="checkbox"
                                    className='high_load'
                                    id={'high_load'+item.name+key_item}
                                    name="high_load"
                                    key={item.name}
                                    checked={checkboxTableStates[parameters.dataName+'_id_'+item.name] || false}
                                    onClick={() => handleCheckboxClick( parameters.dataName+'_id_'+item.name)}
                                />
                                <label htmlFor={'high_load'+item.name+key_item} className="lb"></label>
                            </div>
                        </div>
                    </td>
                    {item.value.map((value, k) => (
                      <td key={k}>
                          <div className="value_table">
                              <div>
                                  {value.value ? renderValueTable(renameItems(item.name), value.value) : '-'}<br/>
                                  <span>{subValueRow(parameters, k, item.name)}</span>
                              </div>
                              {percentValue(parameters, k, item.name)}
                          </div>
                      </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
    );
};

export default TableOverview;
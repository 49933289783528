import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import logo from './img/logo.png';
import './styles/login.scss';

const LoginForm = ({ history }) => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://newapi.godoko-fsp.com/login/', formData);
            const { token, first_name, manager, last_name } = response.data;

            localStorage.setItem('authToken', token);
            localStorage.setItem('first_name', first_name);
            localStorage.setItem('last_name', last_name);
            localStorage.setItem('manager', manager);

            // Redirect the user to another page
            navigate('/overview_month', {replace: true});
            navigate(0);
        } catch (error) {
            console.error('Authentication error', error);
        }
    };

    return (
        <div className="login_form">
            <div className="login_form__header">
                <img className="login_form__header__logo" src={logo} alt="none"/>
                <div className="login_form__header__title">LOG IN</div>
                <div className="login_form__header__formText">Enter your email and password below</div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="login_form__field_group">
                    <label htmlFor="login_form__label email">Email</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="login_form__field_group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                </div>
                <button type="submit" className="login_form__submit">Log In</button>
            </form>
        </div>
    );
};

export default LoginForm;
import React, {useEffect, useState} from "react";
import {ApiGetRequest} from "../api/ApiGetRequest";
import {useLocation, useNavigate} from "react-router-dom";
import Select from "react-select";

const SelectAppList = (parameters) => {
    const [data, setData] = useState([]);
    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    const [valueSelect, setValueSelect] = parameters.selectState;
    const [loading, setLoading] = parameters.dataLoading;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            const result = await ApiGetRequest('appList');
            setData(result.app_list);
        };
        fetchDataFromApi().then();
    }, [pathname]);

    const styleSelect = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #DFE0EB',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:focus': {
                border: '0 !important',
            },
            fontSize: '20px',
            width: pathname === '/overview_month' || pathname === '/overview' || pathname === '/'
                ? '92%'
                : '55vw'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#E7EBFF' : null,
            color: 'black',
        }),
        menu: (provided, state) => ({
            ...provided,
            width: pathname === '/overview_month' || pathname === '/overview' || pathname === '/'
                ? '92%'
                : '55vw',
            zIndex: '100',
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    const handleSelectChange = (selectedOption) => {
        setValueSelect(selectedOption);
        setLoading(true);
        if(splitLocation[1] !== 'overview_month' && splitLocation[1] !== '/' && splitLocation[1] !== 'overview') {
            navigate('/overview_month', {replace: true});
        }
    };

    return (
        <Select
            options={data}
            value={valueSelect}
            onChange={handleSelectChange}
            styles={styleSelect}
            getOptionLabel={(option) => option.package}
            getOptionValue={(option) => option.id}
            formatOptionLabel={option => (
                <div className="app-select-option">
                    {option.icon ?  <img src={option.icon} height="30px" width="30px"/> : null}
                    <span>
                        {option.package}
                        <span className="option_computer"> {option.computer ? '(' + option.computer + ')' : null} </span>
                        <span className="option_comment"> {option.comment}</span>
                    </span>
                </div>
                )}
            placeholder="Search"
        />
    );
}

export default SelectAppList;
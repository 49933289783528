import React, {useEffect, useState} from 'react';
import {ApiPostRequest} from "../api/ApiPostRequest";
import TableRtbGeo from "../components/RtbGeo/TableRtbGeo";
import "../styles/style.scss";
import FiltersRtbGeo from "../components/RtbGeo/FiltersRtbGeo";
import Moment from "moment";

function RtbGeo(parameters) {
    const [isOpen, setIsOpen] = parameters.openSideBar;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOptionProfit, setSelectedOptionProfit] = useState({value: false, label: 'Profit filter : NO'});
    const [selectedOptionTop, setSelectedOptionTop] = useState({value: 'all', label: 'All GEO'});

    const [sortParameter, setSortParameter] = useState('ads_installs-');
    const [activeItemIndex, setActiveItemIndex] = useState(1);

    const currentDate = new Date();
    const defaultDateStart = new Date();
    const defaultDateEnd = new Date();

    defaultDateStart.setDate(currentDate.getDate() - 30);
    defaultDateEnd.setDate(currentDate.getDate() - 1);

    const [dateRange, setDateRange] = useState([defaultDateStart, defaultDateEnd]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const result = await ApiPostRequest('RTBGEOs',
                    {
                        "start_date": Moment(dateRange[0]).format('DD.MM.YYYY').toString(),
                        "end_date": Moment(dateRange[1]).format('DD.MM.YYYY').toString(),
                        "top": selectedOptionTop.value,
                        "filter": selectedOptionProfit.value,
                        "sort": sortParameter,
                    }
                );
                setData(result.GEO_list);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if(loading) {
            setIsOpen(false);
        }

        fetchDataFromApi().then(() => {});
    }, [selectedOptionProfit, selectedOptionTop, dateRange, sortParameter]);

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page apps">
                <FiltersRtbGeo
                    loading={[loading, setLoading]}
                    selectedOptionProfit={[selectedOptionProfit, setSelectedOptionProfit]}
                    selectedOptionTop={[selectedOptionTop, setSelectedOptionTop]}
                    dateRange={[dateRange, setDateRange]}
                />
                <TableRtbGeo
                    data={data}
                    sort={[sortParameter, setSortParameter]}
                    loading={[loading, setLoading]}
                    activeItemIndex={[activeItemIndex, setActiveItemIndex]}
                />
        </div>
    );
}

export default RtbGeo;
import React from 'react';
import {useLocation} from "react-router-dom";
import Tooltip from "../Tooltip";

const TableOverview = (parameters) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const [hiddenElements, setHiddenElements] = parameters.hiddenElements;
    const [checkboxTableStates, setCheckboxTableStates] = parameters.checkboxState;

    const addDollars = [
        'Revenue Subscriptions', 'Revenue Admob',
        'CPM Admob', 'Costs Adwords',
        'Average subscription', 'Profit',
        'IPI', 'StPS', 'IPS', 'Cost per install',
        'ARPI monthly', 'ARPI 12m', 'NPI', 'NPI 12m', 'Cost per Install',
        'ARPI ad', 'Cost per install Adwords', 'Revenue Ad', 'CPM Ad', 'CPM Admob t', 'ARPI sub'
    ];

    const tips = {
        new_subs: 'All new subscriptions per month (paid and unpaid)',
        paid_up_subscription: 'All new paid paid subscriptions (only monthly) per month',
        StPS_t: 'Percentage of paid trials (only monthly)',
        install_to_sub: 'Percentage of installs converting to a paid subscription (only monthly)',
        resub_month: 'Resub sum per year (only monthly)',
        active_subscriptions: 'Number of all paid subscriptions per month',
        all_subscription: 'Number ofall active subscriptions on the last day of the month',
        CPI_adwords: 'Сost per 1 adwords install',
        ARPI_sub: 'Average revenue per install (monthly and yearly subs)',
        ARPI_ad: 'Average ads revenue per paid install',
        ARPI_12m: 'Average revenue per install (monthly and yearly subs) per year',
        ROI_12m: 'Return of investments per year',
        IPS: 'Percentage of installs converting to a paid subscription (only monthly)',
        StPS: 'Percentage of paid trials (only monthly)',
        CPI: 'Сost / (adwords + organic)'
    };

    const handleClick = (event) => {
        const clickedElementId = event.target.id;
        if (hiddenElements.includes(clickedElementId)) {
            setHiddenElements(hiddenElements.filter(id => id !== clickedElementId));
        } else {
            setHiddenElements([...hiddenElements, clickedElementId]);
        }
    };

    const data = Object.entries(parameters.data).map(([key, value]) => ({
        name: key,
        value: Object.entries(value).map(([key_sub, value_sub])=> (value_sub))
    }));

    const handleCheckboxClick = (value) => {
        setCheckboxTableStates({});
        setCheckboxTableStates((prevState) => ({
            ...prevState,
            [value]: !prevState[value],
        }));

    };

    function itemsName(name) {
        if(tips[name]) {
            return (<div className="tips_name">
                <div className="name">{renameItems(name)}</div>
                <Tooltip dataTips={tips[name]}/>
            </div>);
        }
        return renameItems(name);
    }

    function renameItems(name) {
        return parameters.listNewNames[name] ?? name;
    }

    function renderValueTable(name, value) {
        if(name.includes('Trial to Sub')
            || name.includes('Install to Sub')
            || name.includes('Outflow users')
            || name.includes('ROI 12m')
            || name.includes('Resub 2')
        ) {
            return  value + ' %';
        }

        return (addDollars.indexOf(name) > -1 )? value + ' $' : value;
    }

    return (
        <div className="table-block" id={parameters.dataName}>
            {parameters.dataName && parameters.dataName !== 'default' ?
                <table className="table-block_title">
                    <tbody>
                    <tr>
                        <td
                            id={parameters.dataName}
                            className={
                                !hiddenElements.includes(parameters.dataName) ? 'parent_table sub hidden' : 'parent_table sub visible'
                            }
                            onClick={handleClick}>{parameters.listNewNames[parameters.dataName] ?? parameters.dataName}</td>
                            {parameters.dataDetePeriod.map((items, k) => (
                                <td key={k}></td>
                            ))}
                        </tr>
                    </tbody>
                </table> : null}
                    <table className={!hiddenElements.includes(parameters.dataName) ? 'hidden' : 'visible'}>
            <tbody>
            {data.map((item, key_item) => (
                <tr key={key_item}>
                    <td>
                        <div className="name_row">
                            <div>{itemsName(item.name)}</div>
                            <div className={'checkbox_table '+parameters.dataName}>
                                <input
                                    readOnly
                                    type="checkbox"
                                    className='high_load'
                                    id={'high_load'+item.name+key_item}
                                    name="high_load"
                                    key={item.name}
                                    checked={checkboxTableStates[parameters.dataName+'_id_'+item.name] || false}
                                    onClick={() => handleCheckboxClick( parameters.dataName+'_id_'+item.name)}
                                />
                                <label htmlFor={'high_load'+item.name+key_item} className="lb"></label>
                            </div>
                        </div>
                    </td>
                    {item.value.map((value, k) => (
                      <td key={k}>
                          <div className="value_table">
                              <div>
                                  {value.value ? renderValueTable(renameItems(item.name), value.value) : '-'}<br/>
                              </div>
                          </div>
                      </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
    );
};

export default TableOverview;
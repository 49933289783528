import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";

const FiltersRtbGeo = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [selectedOptionTop, setSelectedOptionTop] = parameters.selectedOptionTop;
    const [selectedOptionProfit, setSelectedOptionProfit] = parameters.selectedOptionProfit;
    const [dateRange, setDateRange] = parameters.dateRange;
    const [startDate, endDate] = dateRange;

    const optionsTop = [
        {
            value: 10,
            label: 'Top 10'
        },
        {
            value: 20,
            label: 'Top 20'
        },
        {
            value: 50,
            label: 'Top 50'
        },
        {
            value: 100,
            label: 'Top 100'
        },
        {
            value: 'all',
            label: 'All GEO'
        },
    ];

    const optionProfit = [
        {
            value: true,
            label: 'YES'
        },
        {
            value: false,
            label: 'NO'
        },
    ];

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px', minHeight: '32px', maxHeight: '32px'}),
        menuList: base => ({...base, maxHeight: "270px", textAlign: 'center'}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
        singleValue: () => ({ left: "30px", position: "relative", top: "-19px", height: "10px" }),
        ValueContainer: () => ({ left: "30px", position: "relative", top: "-19px", height: "10px" }),
        input: () => ({ margin: "5px 10px", display: "inline-grid", gridArea:" 1/1/2/3", gridTemplateColumns: "10px", color: 'transparent'}),
    };

    const handleSelectTopChange = (selectedOption) => {
        setSelectedOptionTop(selectedOption);
        setLoading(true);
    };

    const handleSelectProfitChange = (selectedOption) => {
        setSelectedOptionProfit(selectedOption);
        setLoading(true);
        selectedOption.label = 'Profit filter : ' + selectedOption.label;
    };

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    return (
        <div className="filters_wrapper">
            <div className="field_group">
                <DatePicker
                    disabledKeyboardNavigation
                    maxDate={new Date()}
                    minDate={new Date('07.01.2023')}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd.MM.yyyy"
                    selectsRange={true}
                    onChange={handleDateChange}
                    isClearable={true}
                    placeholderText="Date range"
                />
            </div>
            <div className="field_group">
                <Select
                    value={selectedOptionTop}
                    onChange={handleSelectTopChange}
                    options={optionsTop}
                    className="search_input top"
                    placeholder="Search"
                    styles={styleSelect}
                />
            </div>
            <div className="field_group">
                <Select
                    value={selectedOptionProfit}
                    onChange={handleSelectProfitChange}
                    options={optionProfit}
                    className="search_input profit"
                    placeholder="Search"
                    styles={styleSelect}
                />
            </div>
        </div>
    );
}

export default FiltersRtbGeo;